.parent-tag-container {
    background-color: #EFEFEF;
    max-width: 650px;
    max-height: 5vh;
    padding: 10px 0.7rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
}

.parent-tag-container {}


.parent-tag-container .sp-tag-container,
.tag-container .sp-tag-container {
    display: flex;
    flex-wrap: wrap;
}

.tag-container {
    background-color: #EFEFEF;
    max-width: 650px;
    max-height: 5vh;
    padding: 10px 0.7rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
}

.tag-container .sp-tag {
    color: #A9A9A9;
    font-size: 13px;
    padding: 5px;
    margin-left: 6px;
    margin-right: 5px;
}

.tag-container .sp-tag-check {
    color: #1677ff;
    font-size: 13px;
    padding: 5px 10px;
    margin-left: 6px;
    margin-right: 5px;
    background: rgba(213, 212, 212, 0.34);
    border-radius: 10px;
    font-weight: bold;
}

.content-container {
    background-color: #EFEFEF;
    max-width: 650px;
    /*min-height: calc(100vh - 20px);*/
    min-height: 115vh;
    padding: 3px 2rem;
    margin: 0 auto;
    margin-bottom: 45px;
}

.bottom-tab {
    width: 100%;
    position: fixed;
    bottom: 0;
}

.adm-card-body {
    padding: 1rem 1.5rem;
}

.adm-card-body .adm-divider-horizontal {
    margin: 5px 0 !important;
}

.content {

}

.left-card-title {
    text-align: left;
}

.right-card-title {
    text-align: left;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.left-card-title_img_box{
    width: 50px;
    height: 50px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.left-card-title_img_box>img {
    width: 100%;
    height: 100%;
}
.right-card-title>h3 {
    margin-bottom: 2px;
}

.right-card-title>p {
    color: #A5A5A5;
    font-size: 0.7rem;
}

.company-name {
    color: #A5A5A5;
    font-size: 12px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.itemFooterL{
    flex: 1;
}
.itemFooterR{
    margin-right: 10px;
}
.card-cover {
    margin-bottom: 10px;
}

.parent-tag-container .adm-space-horizontal > .adm-space-item,
.tag-container .adm-space-horizontal > .adm-space-item{
    margin-bottom: 10px;
}

.bot-hint-div {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    /* align-items: center; */
    margin: 2px; 
    margin-top: 15px;
    padding:15px 20px;
    border-radius: 10px; 
    background-color: white;
    min-width: 100px; 
    min-height: 100px; 
  }

.bot-hint-div h2{
    text-align: left;
    font-size: 14px;
    /* font-style: italic; */
    color: #5184de;
}
#vipListTopId{
    height: 100vh;
    overflow: auto;
    padding-bottom: 20rem;
    box-sizing: border-box;
}
#vipListTopId .cardTop{
    height: auto;
    width: 100%;
    position: relative;
    background: url('../../assert/cardTopBg.jpg') no-repeat;
    padding-top: 7.2rem;
    padding-bottom: 3rem;
    box-sizing: border-box;
}
#vipListTopId .cardTopBg{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -1rem;
}
#vipListTopId .cardItem, .cardItemBg{
    width:71.8rem;
    height: 22.5rem;
    background: url('../../assert/nVipBg.jpg') no-repeat;
    margin: 0 auto;
    border-radius: 2.3rem;
    box-sizing: border-box;
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#vipListTopId .cardItemBg{
    background: url('../../assert/yVipBg.jpg') no-repeat;
}
#vipListTopId .cardItem .cardLeft{
    margin-left: 2.5rem;
}
#vipListTopId .cardItem .cardLeft .title{
    font-size: 3.8rem;
    color: #fff;
    font-weight: bold;
    line-height: 4.4rem;
}
#vipListTopId .cardItem .cardLeft .tip{
    font-size: 2.8rem;
    color: #fff;
    line-height: 4.4rem;
}
#vipListTopId .cardItem .cardLeft .expireDatTip{
    margin-top: 1rem;
    font-size: 2.4rem;
}
#vipListTopId .cardItem .cardright{
    position: relative;
    width: 24.2rem;
    height: 21.7rem;
}
#vipListTopId .cardItem .cardright img{
    width: 24.2rem;
    height: 21.7rem;
    position: absolute;
    left: 0px;
    top: 3.5rem;
}
#vipListTopId .vipList{
    width: 100%;
    overflow-x: auto;
    margin-top: 2rem;
    padding: 0 2.5rem;
    box-sizing: border-box;
    white-space: nowrap;
    text-align: left;
}
#vipListTopId .vipList>div{
    display: inline-block;
}
::-webkit-scrollbar {
    display: none;
}
#vipListTopId .vipList .vipItem{
    width: 24rem;
    height: 24rem;
    border-radius: 2.3rem;
    border: 2px solid #DCDADA;
    background: #F3F6F8;
    box-sizing: border-box;
    padding: 2.5rem 1.5rem;
    text-align: left;
    margin-right: 2.5rem;
    display: inline-block;
}
#vipListTopId .vipList .activeItem {
    width: 24rem;
    height: 24rem;
    border-radius: 2.3rem;
    border: 2px solid #1368FF;
    background: #F3F6F8;
    box-sizing: border-box;
    padding: 2.5rem 1.5rem;
    text-align: left;
    margin-right: 2.5rem;
    display: inline-block;
}

#vipListTopId .vipList .vipItem .title{
    font-size: 2.8rem;
    color: #000;
    line-height: 4.4rem;
    font-weight: bold;
}
#vipListTopId .vipList .vipItem .price{
    font-size: 2.8rem;
    color: #B1B1B1;
    line-height: 4.4rem;
    text-decoration: line-through;
}
#vipListTopId .vipList .vipItem .fPrice .priceIcon{
    font-size: 3.2rem;
    padding-right:0.5rem;
}
#vipListTopId .vipList .vipItem .fPrice{
    font-size: 5.6rem;
    line-height: 6.4rem;
    color: #333333;
    font-weight: bold;
}
#vipListTopId .vipList .vipItem .day{
    font-size: 2.8rem;
    color: #8A8A8A;
    text-align: center;
    line-height: 4.4rem;
}



#vipListTopId .vipList .activeItem .title{
    font-size: 2.8rem;
    color: #000;
    line-height: 4.4rem;
    font-weight: bold;
}
#vipListTopId .vipList .activeItem .price{
    font-size: 2.8rem;
    color: #B1B1B1;
    line-height: 4.4rem;
    text-decoration: line-through;
}
#vipListTopId .vipList .activeItem .fPrice .priceIcon{
    font-size: 3.2rem;
    padding-right:0.5rem;
}
#vipListTopId .vipList .activeItem .fPrice{
    font-size: 5.6rem;
    line-height: 6.4rem;
    color: #1368FF;
    font-weight: bold;
}
#vipListTopId .vipList .activeItem .day{
    font-size: 2.8rem;
    color: #8A8A8A;
    text-align: center;
    line-height: 4.4rem;
}
#vipListTopId .cardTitleLine{
    padding: 0 4.5rem;
}
#vipListTopId .cardTitleLine .cardTitleBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.5rem;
}
#vipListTopId .cardTitleLine .cardTitleBox .cardLineLeft{
    display: flex;
    justify-content: left;
    align-items: center;
}
#vipListTopId .cardTitleLine .cardTitleBox .cardLineLeft img{
    width: 3.2rem;
    height: 3.2rem;
}
#vipListTopId .cardTitleLine .cardTitleBox .cardLineLeft .cardText{
    font-size: 3.6rem;
    color: #fff;
    padding-left: 1.2rem;
    font-weight: bold;
}
#vipListTopId .cardTitleLine .cardTitleBox .cardLineRight{
    color: #fff;
    font-size: 3rem;
}
#vipListTopId .cardTitleLine .cardTitleBox .cardLineRight .cardCount{
    padding-right: 1.2rem;
}
#vipListTopId .cardTitleLine .cardTitleBox .cardLineRight .cardCountIcon svg{
    font-size: 3.2rem!important
}
#vipListTopId .cardTitleLine .cardTipBox{
    font-size: 2.8rem;
    color: #c1c1c1;
    text-align: left;
    line-height: 4.4rem;
    margin-bottom: 3.5rem;
}
#vipListTopId .tagBox{
    height: 10.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
#vipListTopId .tagBox .tagItem{
    text-align: center;
    width: 40%;
    color: #8a8a8a;
    font-size: 3.2rem;
    font-weight: bold;
}
#vipListTopId .tagBox .tagItem .activeSpan{
    color: #000;
    border-bottom: 0.4rem solid #1368FF;
    border-radius: 0.3rem;
    padding-bottom: 0.3rem;
}
#vipListTopId .vipDetailBox{
    margin-top: 5.5rem;
    padding: 0 2.5rem;
}
#vipListTopId .vipDetailBox .detailTitleBox{
    text-align: left;
}
#vipListTopId .vipDetailBox .detailTitleBox .titleText{
    font-size: 3.2rem;
    color: #333;
    line-height: 4.4rem;
    font-weight: bold;
}
#vipListTopId .vipDetailBox .detailTitleBox .tipText{
    font-size: 2.4rem;
    color: #8a8a8a;
    line-height: 4.4rem;
}
#vipListTopId .vipDetailBox .detailImg{
    margin-top: 1.5rem;
}
#vipListTopId .vipDetailBox .detailImg img{
    width: 100%;
}
#vipListTopId .footerBox{
    padding: 3rem 0;
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0;
    background: #fff;
}

#vipListTopId .footerBox > .footer{
    background: linear-gradient(to right,#1368FF, #71A4FF);
    width: 71.7rem;
    height: 13.6rem;
    border-radius: 2rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#vipListTopId .footerBox > .footer > .footerLeft{
    margin-left: 4rem;
}
#vipListTopId .footerBox > .footer > .footerLeft > .title{
    font-size: 3.2rem;
    color: #fff;
    line-height: 4.4rem;
    text-align: left;
    font-weight: normal;
}
#vipListTopId .footerBox > .footer > .footerLeft > .price{
    font-size: 3.2rem;
    color: #fff;
    line-height: 4.4rem;
    text-align: left;
}
#vipListTopId .footerBox > .footer > .footerRight{
    width: 16.6rem;
    height: 6.4rem;
    background: #fff;
    border-radius: 3rem;
    text-align: center;
    line-height: 6.4rem;
    color: #1368FF;
    font-size: 2.4rem;
    margin-right: 3rem;
}
.loadClass{
    height: 100%;
    position: fixed;
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.loadTitle{
    margin-top:1.5rem
}
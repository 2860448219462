.my-app-card {
  height: 120px;
  /* border: 1px dashed #ccc; */
  margin-top: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.mc-first,
.mc-second,
.mc-third {
  margin: 1.5rem auto;
}

.mc-second {
  text-align: left;
  margin-left: 10px;
  padding-top: 4px;
}
.mc-third{
  height: calc(100% - 3rem);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.vipBlock{
  margin-top: 0.7rem;
  width: auto;
  border: 2px solid #1677ff;
  padding: 3px 3px;
  color: #1677ff;
  font-weight: bold;
  border-radius: 3px;
}
.vipTime{
  font-size: 13px;
  color: grey;
  font-weight: bold;
}
.btnLine{
  color: rgb(16, 142, 233);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ddd;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 15px;
}
.lineLeft, .lineRight{
  flex: 0.5;
}
.lineLeft{
  border-right: 1px solid #333;
}
.lineTitle{
  font-size: 1rem;
  color: #333;
  font-weight: bold;
}
.lineTip{
  font-size: 0.7rem;
  color: #333;
}
.listClass{
  background: #fff;
  border-radius: 8px;
  width: 71.8rem;
  box-sizing: border-box;
  padding: 0 3.2rem;
  margin: 0 AUTO;
  margin-top: 2.3rem;
  
}
.listItemClass{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2.8rem;
  height: 10rem;
  color: #000;
}
.height10 {
  height: 10rem;
  line-height: 10rem;
}
.copyTitle{
}
.copyBtn{
  color: #1677ff;
}
.dialogInputClass{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dialogTitle{
  width: 100px;
}
.dialogTable{
  margin-top: 15px;
  border: 1px solid #ededed;
}
.dialogTableHead{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  padding: 5px;
  background: #ddd;
}
.dialogTableBody{
  font-size: 2rem;
  padding-top: 5px;
  max-height: 300px;
  overflow: auto;
}
.tableTr{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  margin-bottom: 5px;
}
.one{
  width: 40%;
  word-break: break-word
}
.two{
  width: 30%;
  word-break: break-word
}
.three{
  width: 30%;
  word-break: break-word
}
.my-app-center {
  height: 100px;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.mac-first {
  padding-top: 10px;
}

.mac-first h1{
  text-align: left;
}

.mac-second {
  padding-top: 10px;
}

.ava-area {
  width: 96px;
  margin: 0 auto;
}

.qr-area {
  margin-top: 20px;
}

.btn-area {
  margin-top: 20px;
}


.btn-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;  
  margin: 0 1.8rem;
  background-color: #EFEFEF;
  font-size: 15px;
    color: grey;
}

.myBox{
  height: 100vh;
  position:relative;
}
.backGround{
  position: absolute;
  left: 0px;
  top:0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #F5F6FB, #F4F5FA);
}
.topBg{
  width: 100%;
  height: 30rem;
  margin-top:-1rem;
}
.bottomBg{
  width: 100%;
  height: 26.9rem;
  margin-top: 25rem;
}
.contentBox{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
.userBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8.4rem;
}
.userBox > .info{
  display: flex;
  justify-content: left;
  align-items: center;
}
.userBox > .info .infoImg{
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  overflow: hidden;
  background: #ddd;
  margin-left: 4.7rem;
}
.userBox > .info .infoImg img{
  width: 100%; height: 100%;
}
.userBox > .info .infoNameBox{
  margin-left: 1.4rem;
}
.userBox > .info .infoNameBox .name{
    font-size: 3.6rem;
    color: #000;
    font-weight: bold;
    line-height: 4.4rem;
    text-align: left;
}
.userBox > .info .infoNameBox .id{
  text-align: left;
  line-height: 4.4rem;
  color: #8A8A8A;
  font-size: 2.4rem;
}
.vipBox{
  margin-right: 3.5rem;
}
.vipBox > .icon{
    background: linear-gradient(to bottom, #E9BA7D, #EECF9E);
    width: 8rem;
    height: 4rem;
    border-radius: 3px;
    margin: 0 auto;
}
.vipBox > .icon > img{
  width: 4.8rem;
  height: 4rem;
}
.vipBox > .vipTime{
  color: #8A8A8A;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 4.4rem;
}
.vipLine{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  width: 71.8rem;
  margin: 0 auto;
  margin-top: 4.8rem;
  padding: 3rem 0;
  box-shadow: 0px 1px 4px #EDEEF3;
}
.vipLine .lineItem{
  width: 50%;
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vipLine .lineItem .lineImg{
  width: 8rem;
  height: 8rem;
  border-radius: 10px;
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vipLine .lineItem .lineImg img{
  width: 4rem;
  height: 4rem;
  
}
.vipLine .lineItem .lineText{
  margin-left: 1.5rem;
}
.vipLine .lineItem .lineText .title{
  font-size: 3rem;
  font-weight: normal;
  color: #000;
  line-height: 4.4rem;
}
.vipLine .lineItem .lineText .tip{
  font-size: 2.4rem;
  color: #8A8A8A;
  line-height: 4.4rem;
}
.blockBox{
    display: flex;
    justify-content: space-between;
    width: 71.8rem;
    margin: 0 auto;
    margin-top: 2.3rem;
}
.blockBox .blockItem{
  width: 34.4rem;
  height: 24rem;
  border-radius: 2.4rem;
  background: #fff;
  box-shadow: 0px 1px 4px #EDEEF3;
  padding: 2rem;
  box-sizing: border-box;
}
.blockBox .blockItem .blockTitle{
  font-size: 3.2rem;
  line-height: 4.4rem;
  color: #000;
  font-weight: bold;
  text-align: left;
}
.blockClear{
  display: flex;
  justify-content: center;
}
.blockClear .activeLine{
  flex: 1;
}
.blockClear .activeLine .activeTitle{
  color: #8A8A8A;
  line-height: 4.4rem;
  font-size: 2.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.blockClear .activeLine .activeNum{
  color: #1368FF;
  font-size: 5.6rem;
  font-weight: bold;
}
.blockSubs{
  display: flex;
    justify-content: center;
}
.blockSubs .subItem .subTitle{
  color: #8A8A8A;
  line-height: 4.4rem;
  font-size: 2.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
.blockSubs .subItem .subCount{
  color: #1368FF;
  font-size: 5.6rem;
  font-weight: bold;
}
.loginoutBtn{
  font-size: 3.2rem;
  color: #FF2929;
}
.appMain{
    position: fixed;
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right, #F5F6FB, #F4F5FA);
    top: 0;
    left: 0;
}
#customVipOrderDetail7D9Us0h .cardTop{
    height: 40rem;
    width: 100%;
    position: relative;
    background: url('../../assert/cardTopBg.jpg') no-repeat;
    padding-top: 3rem;
    padding-bottom: 3rem;
    box-sizing: border-box;
}
#customVipOrderDetail7D9Us0h .cardTopBg{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
#customVipOrderDetail7D9Us0h .cardItem{
    width:71.8rem;
    height: 22.5rem;
    background: url('../../assert/yVipBg.jpg') no-repeat;
    margin: 0 auto;
    border-radius: 2.3rem;
    box-sizing: border-box;
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#customVipOrderDetail7D9Us0h .cardItem .cardLeft{
    margin-left: 2.5rem;
}
#customVipOrderDetail7D9Us0h .cardItem .cardLeft .title{
    font-size: 3.8rem;
    color: #fff;
    font-weight: bold;
    line-height: 4.4rem;
}
#customVipOrderDetail7D9Us0h .cardItem .cardLeft .tip{
    font-size: 2.8rem;
    color: #fff;
    line-height: 4.4rem;
}
#customVipOrderDetail7D9Us0h .cardItem .cardright{
    position: relative;
    width: 24.2rem;
    height: 21.7rem;
}
#customVipOrderDetail7D9Us0h .cardItem .cardright img{
    width: 24.2rem;
    height: 21.7rem;
    position: absolute;
    left: 0px;
    top: 3.5rem;
}
#customVipOrderDetail7D9Us0h .cardTop .payBox{
    position: absolute;
    width: 100%;
    left: 0;
    top: 30rem;
}
#customVipOrderDetail7D9Us0h .cardTop .payBox .payMain{
    height: 26rem;
    width: 71.8rem;
    background: #fff;
    border-radius: 2.3rem;
    margin: 0 auto;
}
#customVipOrderDetail7D9Us0h .cardTop .payBox .payMain .payTitle{
    padding-top: 2.5rem;
    font-size: 4rem;
    line-height: 4.4rem;
    color: #000;
    position: relative;
}
#customVipOrderDetail7D9Us0h .cardTop .payBox .payMain .payTitle span{
    font-size: 2.4rem;
    position: absolute;
    right: 1.5rem;
    color: #8A8A8A;
}
#customVipOrderDetail7D9Us0h .cardTop .payBox .payMain .payPrice{
    font-size: 5.4rem;
    font-weight: bold;
    margin-top: 3rem;
    color: #1368FF;
}
#customVipOrderDetail7D9Us0h .cardTop .payBox .payMain .payPrice .payUnit{
    font-size: 3rem;
}
#customVipOrderDetail7D9Us0h .cardTop .payBox .payMain .payTime{
    font-size: 2.8rem;
    line-height: 4.4rem;
    color: #8A8A8A;
    margin-top: 1rem;
}
#customVipOrderDetail7D9Us0h .timeCount{
    color: #1368FF;
    font-weight: bold;
}

#customVipOrderDetail7D9Us0h .footerBox{
    padding: 3rem 0;
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0;
}
#customVipOrderDetail7D9Us0h .footerBox > .footer{
    background: linear-gradient(to right,#1368FF, #71A4FF);
    width: 71.7rem;
    height: 13.6rem;
    border-radius: 2rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#customVipOrderDetail7D9Us0h .footerBox > .footer > .footerLeft{
    margin-left: 4rem;
}
#customVipOrderDetail7D9Us0h .footerBox > .footer > .footerLeft > .title{
    font-size: 3.2rem;
    color: #fff;
    line-height: 4.4rem;
    text-align: left;
    font-weight: normal;
}
#customVipOrderDetail7D9Us0h .footerBox > .footer > .footerLeft > .price{
    font-size: 3.2rem;
    color: #fff;
    line-height: 4.4rem;
    text-align: left;
}
#customVipOrderDetail7D9Us0h .footerBox > .footer > .footerRight{
    width: 16.6rem;
    height: 6.4rem;
    background: #fff;
    border-radius: 3rem;
    text-align: center;
    line-height: 6.4rem;
    color: #1368FF;
    font-size: 2.4rem;
    margin-right: 3rem;
}
#customVipOrderDetail7D9Us0h .loadClass{
    height: 100%;
    position: fixed;
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#customVipOrderDetail7D9Us0h .loadTitle{
    margin-top:1.5rem
}
#customVipOrderDetail7D9Us0h .orderDetailBox{
    position: absolute;
    width: 100%;
    top: 56rem;
    left: 0px;
}
#customVipOrderDetail7D9Us0h .listClass{
    background: #fff;
    border-radius: 8px;
    width: 71.8rem;
    box-sizing: border-box;
    padding: 3.2rem;
    margin: 0 AUTO;
    margin-top: 2.3rem;
    
  }
  #customVipOrderDetail7D9Us0h .listItemClass{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2.8rem;
    height: 9rem;
    color: #000;
  }
  #customVipOrderDetail7D9Us0h .copyTitle{

  }
  #customVipOrderDetail7D9Us0h .copyBtnlass{
    color: #8A8A8A;
    font-weight: normal;
  }
  #customVipOrderDetail7D9Us0h .loadClass{
    height: 100%;
    position: fixed;
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#activeBoxId{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(to bottom, #4891FF, #FFFFFF);
    position:relative
}
#activeBoxId .imgBg{
    position: absolute;
    top: 0;
    width: 69.3rem;
    height: 100%;
    background: url("../../assert/activeBg.svg");
    background-size: 100%;
    left: calc((100% - 69.3rem) / 2);
    background-position: 0rem 3rem;
}
#activeBoxId .activeMain{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    min-height: 100vh;
}
#activeBoxId .activeMain .activeContent{
    height: calc(100vh - 8rem);
    overflow: auto;
}
#activeBoxId .activeMainTop{
    padding-top: 3rem;
    margin: 0 auto;
    width: 100vw;
    overflow: hidden;
    box-sizing: border-box;
}
#activeBoxId .activeMainTop .activeLogoLine{
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 3rem;
}
#activeBoxId .activeMainTop .activeLogoLine .logoOne{
    min-width: 14.5rem;
    max-width: 25rem;
}
#activeBoxId .activeMainTop .activeLogoLine .logoTwo{
    width: 7.5rem;
    height: 2.8rem;
}
#activeBoxId .activeMainTop .activeLogoLine img{
    width: 100%;
    height: 100%;
}
#activeBoxId .activeMainTop .activeTitleLine{
    margin-top: 3rem;
    min-height: 11.6rem;
    padding-left: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#activeBoxId .activeMainTop .activeTitleLine .activeTitle{
    font-size: 6rem;
    color: #fff;
    width: calc(100% - 15.6rem);
    text-align: left;
    font-family: ZCOOLQingKeHuangYou;
    line-height: 8rem;
    letter-spacing: 0.8rem;
}
#activeBoxId .activeMainTop .activeTitleLine .activeTitleImg{
    width: 18.6rem;
    height: 17.1rem;
}
#activeBoxId .activeMainTop .activeTitleLine .activeTitleImg img{
    width: 100%;
    height: 100%;
}
#activeBoxId .activeMainTop .activeTimeLine{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5.5rem;
    position: relative;
}
#activeBoxId .activeMainTop .activeTimeLine .pd {
    width: 15.8rem;
    position: absolute;
    right: 8rem;
    top: -7rem;
}
#activeBoxId .activeMainTop .activeTimeLine .flexBox{
    display: flex;
    justify-content: center;
    align-items: center;
}
#activeBoxId .activeMainTop .activeTimeLine  .activePageOver {
    color:#fff;
    font-size:2.5rem;
}
#activeBoxId .activeMainTop .activeTimeLine .timeItem{
    width: 7.2rem;
    height: 8.4rem;
    background: rgba(0,0,0,0.3);
    color:#fff;
    font-size:3.5rem;
    border-radius: 8px;
    line-height: 8.4rem;
    margin-right: 2rem;
}
#activeBoxId .activeMainTop .activeTimeLine .timeText{
    color:#fff;
    font-size:2.5rem;
    margin-bottom: -2rem;
    margin-right: 2rem;
}
#activeBoxId .activeMainTop .activeCodeLine {
    margin-top: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
}
#activeBoxId .activeMainTop .activeCodeLine .pdTwo{
    width: 25.8rem;
    position: absolute;
    left: -9rem;
    top: -10rem;
    transform: rotateY(180deg) rotateZ(-29deg);
}
#activeBoxId .activeMainTop .activeCodeLine .codeTitle{
    background: rgba(0, 0, 0, 0.3);
    font-size: 2.2rem;
    width: auto;
    padding: 1rem 3rem;
    color: #fff;
    border-radius: 4rem;
}
#activeBoxId .activeMainTop .activeCodeLine .codeTitle>span{
    font-size: 3.2rem;
}
#activeBoxId .activeMainBottom{
    padding-top: 2rem;
    width: 100vw;
    overflow: hidden;
}
#activeBoxId .activeDirectDescBox{
    width: 71.6rem;
    margin: 0 auto;
    margin-top: 4rem;
    padding: 0 3rem;
    box-sizing: border-box;
    text-align: left;
}
#activeBoxId .activeDirectDescBox a{
   color: #333!important;
   cursor: pointer;
   text-decoration: underline!important;
}
#activeBoxId .activeMainBottom .activeInt{
    width: 71.8rem;
    text-align: left;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 1rem;
    padding: 2rem;
    box-sizing: border-box;
    position:relative;
}
#activeBoxId .activeMainBottom .activeInt .activeIntBox>p:nth-child(odd) {
    margin-bottom: 1rem;
}
#activeBoxId .activeMainBottom .activeInt .activeIntBox>p:nth-child(even) {
    margin-bottom: 2rem;
}
#activeBoxId .activeMainBottom .activeInt .activeIntBox>p:last-child {
    margin-bottom: 0
}
#activeBoxId .activeMainBottom .activeInt .pdThree{
    width: 9.8rem;
    position: absolute;
    right: 8rem;
    top: -3rem;
    transform: rotateY(180deg) rotateZ(17deg);
}
#activeBoxId .activeMainBottom .activeInt .pdFour{
    width: 20.8rem;
    position: absolute;
    right: -7rem;
    bottom: -64px;
    transform: rotateY(0deg) rotateZ(-53deg);
}
#activeBoxId .activeMainBottom .activeInt .intLine{
    margin-bottom: 2rem;
}

#activeBoxId .activeMainBottom .activeInt .mb0{
    margin-bottom: 0!important;
}
#activeBoxId .activeMainBottom .activeInt .intLine .intTitle{
    font-size: 2.6rem;
    color: #1f1f1f;
    font-weight: bold;
    line-height: 4.4rem;
}
#activeBoxId .activeMainBottom .activeInt .intLine .intcontent{
    font-size: 2.4rem;
    color: #1f1f1f;
    line-height: 4.4rem;
}
#activeBoxId .activeMainBottom .QrcodeBox{
    margin-top: 2rem;
}
#activeBoxId .activeMainBottom .QrcodeBox .outline{
    width: 16rem;
    height: 16rem;
    padding: 1rem;
    background: #fff;
    margin: 0 auto;
}
#activeBoxId .activeMainBottom .BtnBox{
    width: 71.6rem;
    margin:0 auto;
    margin-top: 3.5rem;
    padding:0 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}
#activeBoxId .activeMainBottom .BtnBox .btnClass{
    width: 30.6rem;
    text-align: center;
    height: 8rem;
    line-height: 8rem;
    font-size:3.2rem;
    color:#A26220;
    background: linear-gradient(to right, #E9BA7D, #F0D7AD);
    border-radius: 5rem;
}
#activeBoxId .footerBox{
    padding-top: 2rem;
}
#activeBoxId .footerBox .footerLine{
    
}
#activeBoxId .footerBox .footerLine .footerImg{
    width: 6.5rem;
    height: 4.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
    background: #fff;
    border-radius: 1rem;
    padding: 0.3rem;
    box-sizing: border-box;
}
#activeBoxId .footerBox .footerLine .footerImg img{
    width: 100%;
    height: 100%;
}
#activeBoxId .footerBox .footerLine .footerText{
    font-size: 2.4rem;
    color:#383838;
    line-height: 4.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
#activeBoxId .footerBox .footerLine .footerText span{
    padding:0 1.5rem;
}

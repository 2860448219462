#sharingId{
    width: 100vw;
    height: 100vh;
    position:relative
}
#sharingId .sharingBg{
    width: 100%;
    height: 100%;
}
#sharingId .qrcodeBox{
    position: absolute;
    bottom: 4rem;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: right;
}
#sharingId .qrcodeBox>div{
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 1rem;
    margin-right: 7rem;
}
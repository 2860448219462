.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@font-face {
  font-family: 'ZCOOLQingKeHuangYou';
  src: url('./assert/font/ZCOOLttf.ttf');
  font-weight: normal;
  font-size: normal;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Reset default styles */
*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; /* Inherit the box model from parent element */
}
body {
  font-family: Arial, sans-serif; /* Specify a fallback font family in case user's system does not have Arial installed */
}
h1, h2, h3, p {
  line-height: 1.5; /* Add some vertical spacing between lines of text */
}
a {
  text-decoration: none; /* Remove underline on links by default */
  color: #A5A5A5; /* Use a different color for links than normal text */
}
img {
  max-width: 100%; /* Ensure images don't overflow their container */
  height: auto; /* Maintain aspect ratio when scaling down image */
}
ul, ol {
  list-style: none; /* Remove bullet points or numbering from lists */
}
button {
  cursor: pointer; /* Change mouse pointer style to indicate that it is clickable */
}

.order-container {
    /*width: 100%;*/
    background-color: #EFEFEF;
    max-width: 650px;
    min-height: calc(100vh - 50px);
    padding: 3px 0.7rem;
    margin: 0 auto;
}

.order-body {
    width: 100%;
    height: 15rem;
}
.container {
    width: 100%;
    height: 100%;
    max-width: 650px;
    min-height: calc(100vh - 60px);
    background: aliceblue url("../../assert/bg.png") no-repeat 0 0;
    background-size: 100% 100%;
    margin: 0 auto;
}

.sign-up-box {
    padding: 2.5rem 1.5rem 1.5rem 1.5rem;
}

.sign-title-logo {
    margin: 0 auto;
}

.signup-title {
    text-align: left;
    padding-left: 0.7rem;
    font-size: 1.6rem;
    color: #ffffff;
}

.sign-up-content {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    min-height: calc(69vh);
    background: #ffffff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.sign-form-area {
    text-align: left;
    padding-top: 1rem;
}

.foot-text {
    text-align: left;
    padding-left: 1.5rem;
    margin-top: 1rem;
    color: #A5A5A5;
    font-size: 0.7rem;
}

.foot-text>a {
    color: #1368FF;
}

.grey-btn {
    --adm-color-background: #EFEFEF;
    background-color: var(--adm-color-background);
    color: #A5A5A5;
}

.sign-small-form {
    display: flex;
    margin-top: 1rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    justify-content: flex-end;
}

.sign-form-area .adm-list-body {
    border-top: none!important;
}

.btn-determine-sign {
    padding-top: 1rem;
}

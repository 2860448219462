.detail-container {
    /*width: 100%;*/
    background-color: #EFEFEF;
    max-width: 650px;
    min-height: calc(100vh);
    padding: 0.5rem 2rem;
    margin: 0 auto;
}

.detail-body {
    width: 100%;
    height: 15rem;
}

.detail-banner {
    /*width: 100%;*/
    padding: 0.4rem 1rem 0.4rem 0;
    background: url("../../assert/img_5.png") no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0 auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 106px;
}
.GridClass{
    padding: 1.3rem 2.5rem 1rem 2.5rem;
    background: url("../../assert/img_5.jpg") no-repeat;
    background-size: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 90px;
    overflow: auto;
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.leftBoxGrid{
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: center;
}
.GridItemImg{
    margin-right: 10px;
    width: 55px;
    height: 55px;
    border-radius:4px;
    overflow: hidden;
}
.GridItemImg>img{
    width: 100%;
    height: 100%
}

.GridItemName{
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    flex: 1;
    text-align: left;
}
.GridItemInter{
    margin-left: 10px;
}
.GridItemInter>a{
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 2.9rem;
    color: #fff;
}


.detail-first {
    margin: 1.7rem auto;
}

.detail-second {
    margin: 0 auto;
    text-align: left;
}

.detail-second>h2 {
    padding-top: 2.6rem;
    color: #FFFFFF;

}

.detail-third {
    margin: 0 auto;
}

.detail-third>p {
    padding-top: 2.75rem;
    color: #FFFFFF;
    font-size: 1rem;
    position: relative;
    text-align: right;
}

.detail-third>p a{
    color: #FFFFFF;
}

.detail-third>p img {
    position: absolute;
    top: 42px;
}

.detail-company-content {
    padding: 3rem 2.5rem;
    background-color: #ffffff;
    /* margin-top: -14px; */
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.detail-company-flex {
    width: 100%;
}

.detail-company-name {
    width: 100%;
    text-align: left;
    color: #A5A5A5;
    font-size: 2.3rem;
}

.detail-company-link {
    text-align: right;
    position: relative;
}

.detail-company-link>a {
    color: #1368FF;
    font-size: 2.3rem;

}

.detail-company-link img {
    position: absolute;
    top: 2px;
    right: -16px;

}

.detail-company-content>h2 {
    text-align: left;
    padding-top: 5.5rem;
    padding-bottom: 2.5rem;
}

.detail-company-p {
    color: #A5A5A5;
    font-size: 2.4rem;
    text-align: left;
    padding-bottom: 2rem;
}
#orderListBox1G0{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 50px);
}
#orderListBox1G0 .listMain{
    flex: 1;
    overflow: auto;
    background: linear-gradient(to right, #F5F6FB, #F4F5FA);
    padding-top: 1.5rem;
}
#orderListBox1G0 .card-cover {
    width: 71.8rem;
    margin: 0 auto;
    margin-bottom: 1.5rem;
}
#orderListBox1G0 .adm-card-body {
    padding: 1rem 1.5rem;
}
#orderListBox1G0 .left-card-title_img_box{
    width: 50px;
    height: 50px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
#orderListBox1G0 .left-card-title_img_box>img {
    width: 100%;
    height: 100%;
}
#orderListBox1G0 .right-card-title {
    text-align: left;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#orderListBox1G0 .statusName{
    color: #A5A5A5;
}
#orderListBox1G0 .right-card-title>h3 {
    margin-bottom: 2px;
}
#orderListBox1G0 .right-card-title>p {
    color: #A5A5A5;
    font-size: 0.7rem;
}
#orderListBox1G0 .company-name {
    color: #A5A5A5;
    font-size: 12px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#orderListBox1G0 .itemFooterL{
    flex: 1;
    font-size: 2.4rem;
    color: #1677ff;
    font-weight: bold;
}
#orderListBox1G0 .itemFooterL span{
    font-size: 2rem;
    padding-right:0.5rem
}
#orderListBox1G0 .itemFooterR{
    margin-right: 10px;
}
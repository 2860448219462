.login-container {
    width: 100%;
    height: 100%;
    max-width: 650px;
    min-height: calc(100vh - 60px);
    margin: 0 auto;
}

.up-box {
    padding: 5rem 1.5rem 4rem 1.5rem;
    display: flex;
}

.title {
    font-family: PingFang SC, serif;
    font-size: 5rem;
    color: #000000;
    text-align: left;
    font-weight: bold;
}

.title-logo {
    margin-top: 3.0em;
    margin-left: 3.5em;
}

.form-area {
    margin-left: 0.5em;
    margin-right: 0.5em;
    text-align: left;
}

.small-form {
    display: flex;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    justify-content: space-between;
}

.btn-determine {
    display: flex;
    margin-top: 1rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-top: 2rem;
}

.btn-determine-sign {
    display: flex;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-top: 0.5rem;
}

.pwd-eye {
    position: relative;
}

.pwd-eye .pwd-eye-icon {
    display: block;
    position: absolute;
    right: 1.5rem;
}

.foot-text {
    text-align: left;
    padding-left: 1.5rem;
    margin-top: 1.3rem;
    color: #A5A5A5;
    font-size: 2.4rem;
    margin-bottom: 20px;
}
/* 
.btn-determine-sign .ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .btn-determine-sign .ant-space {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
.codeClass{
    display: flex;
    justify-content: left;
    align-items: center;
}
.codeSendClass{
    width: 118px;
}

